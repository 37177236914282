import React, { ReactElement } from 'react';
import {
  Labeled,
  LabeledProps,
  usePermissions,
  useResourceContext,
} from 'react-admin';
import { canAccess } from '@react-admin/ra-rbac';
import { Actions } from './WithFieldCheckAccess';

interface LabeledWithCheckAccessProps extends LabeledProps {
  action?: Actions;
  children: ReactElement;
  allowFieldAccessByDefault?: boolean;
}

const LabeledWithCheckAccess: React.FC<LabeledWithCheckAccessProps> = ({
  children,
  action,
  allowFieldAccessByDefault,
  ...rest
}): ReactElement => {
  const { permissions } = usePermissions();
  const resource = useResourceContext();
  const resourceForChild = `${resource}.${children.props.source}`;
  const permissionExists = permissions.some(
    (permission) => permission.resource === resourceForChild
  );
  const hasAccess =
    permissionExists || !allowFieldAccessByDefault
      ? canAccess({
          permissions,
          action,
          resource: `${resource}.${children.props.source}`,
        })
      : true;

  return hasAccess ? <Labeled {...rest}>{children}</Labeled> : null;
};

LabeledWithCheckAccess.defaultProps = {
  action: Actions.SHOW,
  allowFieldAccessByDefault: true,
};

export default LabeledWithCheckAccess;
