import { useEffect, useState, useCallback } from 'react';
import { useGetList, useNotify, useUpdate, useUpdateMany } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import useInvalidateResourceQueryCache from '../common/hooks/useInvalidateResourceQueryCache';
import { DEPENDENT_RESOURCE_MATRIX } from '../common/hooks/useInvalidateRelatedResourceQueries';
import { NotificationType } from '../common/enums';
import {
  NotificationContext,
  releaseNotificationContext,
} from '../../provider/notificationContext';
import { getContext, UserContext } from '../../provider/userContext';
import { api } from '../../provider/api';
import {
  getErrorMessage,
  updateLocalUISettings,
} from '../../utils/UtilityFunctions';
import {
  RESOURCE_SMART_CERT_DETAIL,
  RESOURCE_COMPONENT_TEST,
  RESOURCE_NOTIFICATION,
  RESOURCE_SUBSYSTEM,
  RESOURCE_TEST,
  RESOURCE_IMPORT,
  RESOURCE_ATTACHMENT,
} from '../constants';
import { GeneratePdfMessage } from './components/NotificationTitle';
import {
  ITRS_NOTIFICATION_TYPES,
  CERTIFICATE_NOTIFICATION_TYPES,
  PRESERVATION_NOTIFICATION_TYPES,
} from './NotificationBase';
import { Notification } from './type';

export const useNotifications = (notificationContext?: NotificationContext) => {
  const ctx: UserContext = getContext();
  const [updateMany] = useUpdateMany();
  const [update] = useUpdate();
  const queryClient = useQueryClient();
  const invalidateItrQueries = useInvalidateResourceQueryCache(
    RESOURCE_COMPONENT_TEST,
    true
  );
  const invalidateCertQueries = useInvalidateResourceQueryCache(
    RESOURCE_SMART_CERT_DETAIL,
    true
  );
  const invalidateImportQueries =
    useInvalidateResourceQueryCache(RESOURCE_IMPORT);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const notify = useNotify();
  const { data } = useGetList(RESOURCE_NOTIFICATION, {
    filter: { projectId: ctx?.projectId, email: ctx?.id, seen: false },
  });

  const invalidateAttachmentQueries =
    useInvalidateResourceQueryCache(RESOURCE_ATTACHMENT);

  const readNotifications = useCallback(
    async (ids: string[]) => {
      await updateMany(RESOURCE_NOTIFICATION, {
        ids,
        data: { seen: true },
      });
    },
    [updateMany]
  );

  const dismissAllNotifications = useCallback(async () => {
    await updateMany(
      RESOURCE_NOTIFICATION,
      {
        ids: notifications.map((notification) => notification.id),
        data: { seen: true },
      },
      {
        onSuccess: async () => {
          setNotifications([]);
        },
      }
    );
  }, [updateMany, notifications]);

  const readNotification = useCallback(
    async (id: number) => {
      await update(
        RESOURCE_NOTIFICATION,
        {
          id: id,
          data: { seen: true },
        },
        {
          onSuccess: async () => {
            setNotifications(
              notifications.filter((notification) => notification.id !== id)
            );
          },
        }
      );
    },
    [update, notifications]
  );

  const invalidateResourceQueries = useCallback(
    async (resourceName) => {
      await queryClient.invalidateQueries({
        queryKey: [resourceName, 'getList'],
      });
      queryClient.removeQueries({
        queryKey: [resourceName, 'getOne'],
        exact: false,
      });
    },
    [queryClient]
  );

  const invalidateDependentQueries = useCallback(
    async (resourceName) => {
      if (DEPENDENT_RESOURCE_MATRIX.hasOwnProperty(resourceName)) {
        const dependentResources =
          DEPENDENT_RESOURCE_MATRIX[resourceName] ?? [];
        await Promise.all(
          dependentResources.map(async (dependentResource) => {
            await queryClient.invalidateQueries({
              queryKey: [dependentResource],
              exact: false,
            });
          })
        );
      }
    },
    [queryClient]
  );

  useEffect(() => {
    if (data) {
      const result = [];
      const skip_notification_ids = [];

      for (const notification of data) {
        if (!notification.seen) {
          if (
            ITRS_NOTIFICATION_TYPES.includes(notification.notificationType) ||
            CERTIFICATE_NOTIFICATION_TYPES.includes(
              notification.notificationType
            ) ||
            PRESERVATION_NOTIFICATION_TYPES.includes(
              notification.notificationType
            )
          ) {
            skip_notification_ids.push(notification.id);
          } else if (
            notification.notificationType === NotificationType.UI_SETTINGS_RESET
          ) {
            const getAndUpdateLocalUISettings = async () => {
              skip_notification_ids.push(notification.id);
              const response = await api.user.getMyDetails();
              const companyName = ctx.company;
              const uiSettings =
                response.data.companies[companyName]?.uiSettings;

              if (uiSettings) {
                updateLocalUISettings(uiSettings);
              }
            };

            getAndUpdateLocalUISettings().catch((error) => {
              // eslint-disable-next-line no-console
              console.error(getErrorMessage(error, 'Update UI settings error'));
            });
          } else {
            result.push(notification);
          }
        }
      }
      // Read all itrs and ui_settings notifications
      if (skip_notification_ids.length) {
        readNotifications(skip_notification_ids);
      }

      setNotifications(result);
    }
  }, [ctx.company, data, readNotifications]);

  useEffect(() => {
    return () => {
      releaseNotificationContext(ctx?.id);
    };
  }, [ctx?.id]);

  const clearResourceCache = useCallback(
    async (notification) => {
      if (notification?.details?.resourceName) {
        await invalidateResourceQueries(notification?.details?.resourceName);
        await invalidateDependentQueries(notification?.details?.resourceName);
      }
    },
    [invalidateDependentQueries, invalidateResourceQueries]
  );

  const exportNotification = useCallback(
    (notification) => {
      if (
        notification.notificationType === NotificationType.EXPORT ||
        notification.notificationType === NotificationType.EXPORT_REPORT
      ) {
        notify(
          'The export has been successfully completed. Please check your notifications to download it.',
          {
            type: 'success',
          }
        );
      }
    },
    [notify]
  );

  const convertToPdfNotification = useCallback(
    (notification: Notification) => {
      if (
        notification.notificationType ===
        NotificationType.CONVERT_TO_PDF_AND_SAVE_ATTACHMENTS
      ) {
        notify(
          `${notification.details.fileName} has been converted to pdf and added to attachment list for smart-tag-itr with id=${notification.details.recordId}`,
          { type: 'success' }
        );
        invalidateAttachmentQueries();
      }
    },
    [invalidateAttachmentQueries, notify]
  );

  const importNotification = useCallback(
    async (notification) => {
      if (notification.notificationType === NotificationType.IMPORT) {
        await invalidateImportQueries();

        notify(
          'The import has been successfully completed. Please check your notifications for details.',
          {
            type: 'success',
          }
        );
      }
    },
    [invalidateImportQueries, notify]
  );

  const generatePDFNotification = useCallback(
    async (notification) => {
      if (notification.notificationType === NotificationType.GENERATE_PDF) {
        const generatePdfMessage: GeneratePdfMessage = notification?.details;

        await invalidateCertQueries();
        notify(
          `"${generatePdfMessage.fileName}" has been successfully generated. Please check your notifications to download it.`,
          {
            type: 'success',
          }
        );
      }
    },
    [invalidateCertQueries, notify]
  );

  const skipITRCloseoutAndPopulateNotification = useCallback(
    async (notification) => {
      if (ITRS_NOTIFICATION_TYPES.includes(notification.notificationType)) {
        await readNotification(notification.id);
        await invalidateItrQueries();
        return true;
      }

      return false;
    },
    [readNotification, invalidateItrQueries]
  );

  const skipPreservationNotification = useCallback(
    async (notification) => {
      if (
        PRESERVATION_NOTIFICATION_TYPES.includes(notification.notificationType)
      ) {
        await readNotification(notification.id);
      }
    },
    [readNotification]
  );

  const certificateCloseoutNotification = useCallback(
    async (notification) => {
      if (
        notification.notificationType === NotificationType.CERTIFICATE_CLOSEOUT
      ) {
        notify(
          notification?.details?.message ||
            'The Close Out process was finished.',
          {
            type: 'success',
          }
        );
        await invalidateCertQueries();
      }
    },
    [invalidateCertQueries, notify]
  );

  const populateNotification = useCallback(
    async (notification) => {
      if (notification.notificationType === NotificationType.POPULATE) {
        await queryClient.invalidateQueries({ queryKey: [RESOURCE_SUBSYSTEM] });
        await invalidateItrQueries();
      }
    },
    [queryClient, invalidateItrQueries]
  );

  const uiSettingsResetNotification = useCallback(
    async (notification) => {
      if (
        notification.notificationType === NotificationType.UI_SETTINGS_RESET
      ) {
        try {
          await readNotification(notification.id);

          const response = await api.user.getMyDetails();
          const companyName = ctx.company;
          const uiSettings = response.data.companies[companyName]?.uiSettings;
          if (uiSettings) {
            updateLocalUISettings(uiSettings);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(getErrorMessage(error, 'Update UI settings error'));
        }

        return true;
      }

      return false;
    },
    [readNotification, ctx]
  );

  const certificateNotification = useCallback(
    async (notification) => {
      if (
        CERTIFICATE_NOTIFICATION_TYPES.includes(notification.notificationType)
      ) {
        await readNotification(notification.id);
        await invalidateCertQueries();
        return true;
      }

      return false;
    },
    [readNotification, invalidateCertQueries]
  );

  const ITRCopyTemplateNotification = useCallback(
    async (notification) => {
      if (
        notification.notificationType === NotificationType.ITR_COPY_TEMPLATE
      ) {
        await queryClient.invalidateQueries({ queryKey: [RESOURCE_TEST] });
      }
    },
    [queryClient]
  );

  useEffect(() => {
    notificationContext?.userChannel.subscribe(
      'notifications',
      async (message) => {
        const newNotifications = [];

        for (const notification of message.data.notifications) {
          let isSkip = false;

          // Clear cache for resource name
          await clearResourceCache(notification);

          exportNotification(notification);

          importNotification(notification);

          convertToPdfNotification(notification);

          await generatePDFNotification(notification);

          // Skip itr_closeout and populate notifications and clear cache
          isSkip ||= await skipITRCloseoutAndPopulateNotification(notification);

          // Skip preservation notifications
          await skipPreservationNotification(notification);

          await populateNotification(notification);

          await certificateCloseoutNotification(notification);

          isSkip ||= await certificateNotification(notification);

          isSkip ||= await uiSettingsResetNotification(notification);

          await ITRCopyTemplateNotification(notification);

          if (!isSkip) {
            newNotifications.push(notification);
          }
        }

        if (newNotifications.length) {
          setNotifications((prev) => [...newNotifications, ...prev]);
        }
      }
    );

    return () => {
      notificationContext?.userChannel.unsubscribe('notifications');
    };
  }, [
    notificationContext,
    readNotification,
    invalidateItrQueries,
    invalidateCertQueries,
    queryClient,
    notify,
    ctx.company,
    invalidateResourceQueries,
    invalidateDependentQueries,
    clearResourceCache,
    exportNotification,
    importNotification,
    generatePDFNotification,
    skipITRCloseoutAndPopulateNotification,
    populateNotification,
    certificateNotification,
    uiSettingsResetNotification,
    certificateCloseoutNotification,
    ITRCopyTemplateNotification,
    skipPreservationNotification,
    convertToPdfNotification,
  ]);

  return {
    dismissAllNotifications,
    readNotification,
    notifications,
  };
};
