import React, { ReactElement, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import { models, service } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import get from 'lodash/get';
import { getContext, UserContext } from '../../../provider/userContext';
import { RESOURCE_REPORT } from '../../constants';
import { Report } from '../type';
import {
  getPowerBIDefaultSettings,
  getPowerBIProjectIdFilter,
  getPowerBITimeOffsetParam,
  getPowerBITimeZoneFilter,
} from './reportHelper';
import './style.css';

interface PowerBIViewProps {
  report: Report;
}

const getFilters = (report: Report, projectId: number) => {
  return get(report, 'details.requiredFilters') &&
    report.details['requiredFilters'].indexOf('project_id') < 0
    ? [getPowerBITimeZoneFilter()]
    : [getPowerBITimeZoneFilter(), getPowerBIProjectIdFilter(projectId)];
};
const PowerBIView: React.FC<PowerBIViewProps> = ({ report }): ReactElement => {
  const ctx: UserContext = getContext();
  const dataProvider = useDataProvider();
  const [embedConfig, setEmbedConfig] = useState(null);
  const { data: embedData, isLoading } = useQuery({
    queryKey: [RESOURCE_REPORT, report.id],
    queryFn: () =>
      dataProvider.getPowerBIEmbedded(RESOURCE_REPORT, {
        embeddedId: report.id,
      }),
  });

  useEffect(() => {
    if (embedData) {
      setEmbedConfig({
        type: 'report',
        embedUrl: embedData.embedUrl,
        accessToken: embedData.accessToken,
        tokenType: models.TokenType.Embed,
        settings: embedData.details?.settings
          ? embedData.details?.settings
          : getPowerBIDefaultSettings(),
        filters:
          report.powerBiReportType !== 'PaginatedReport'
            ? getFilters(report, ctx?.projectId)
            : undefined,
        parameterValues:
          report.powerBiReportType === 'PaginatedReport'
            ? [
                {
                  name: 'project_id',
                  value: '' + ctx?.projectId,
                },
                getPowerBITimeOffsetParam(),
              ]
            : undefined,
      });
    }
  }, [ctx?.projectId, embedData, report]);

  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        // eslint-disable-next-line no-console
        console.log('Report has loaded');
      },
    ],
    [
      'rendered',
      function () {
        // eslint-disable-next-line no-console
        console.log('Report has rendered');
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<never>) {
        if (event) {
          // eslint-disable-next-line no-console
          console.error(event.detail);
        }
      },
    ],
  ]);

  return !isLoading && embedConfig ? (
    <PowerBIEmbed
      embedConfig={embedConfig}
      eventHandlers={eventHandlersMap}
      cssClassName={'power-bi-component'}
    />
  ) : null;
};

export default PowerBIView;
