import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import DisciplineInput from '../common/DisciplineInput';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { validateLessThanValue } from '../common/validators';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import CustomNumberInput from '../common/CustomNumberInput';
import { required } from '../../utils/UtilityFunctions';
import WithFieldCheckAccess, { Actions } from '../common/WithFieldCheckAccess';
import CurrencyInput from './components/CurrencyInput';
import { Position } from './types';

const PositionEdit: React.FC<EditPropsWithLocation<Position>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Position"
      id="position_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="position"
            label="Position"
            validate={[required(), maxLength(55)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="description"
            label="Description"
            validate={[maxLength(100)]}
            sx={commonStyles.flexBoxItem}
            fullWidth
            multiline
          />
          <DisciplineInput />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <WithFieldCheckAccess
            action={Actions.EDIT}
            hasResourceEditAccess={false}
          >
            <CustomNumberInput
              source="billRate"
              label="Bill Rate"
              fractionDigits={2}
              isPositive
              sx={commonStyles.flexBoxItem}
              validate={[validateLessThanValue(1000)]} // billRate - DECIMAL(5,2)
            />
            <CurrencyInput />
          </WithFieldCheckAccess>
        </Box>
        <MetaDataInputs />
      </Box>
    </LazyFormTab>
    <HistoryTab tableName="tbl_position" />
  </TabbedEditForm>
);

export default PositionEdit;
