import React, { ReactElement, useCallback, useState, Fragment } from 'react';
import { Identifier } from 'react-admin';
import { Typography } from '@mui/material';
import ModalDialog from '../../common/ModalDialog';
import { Notification } from '../type';
import NotificationItemCard from './NotificationItemCard';

interface ImportNotificationProps {
  notification: Notification;
  readNotification: (id: Identifier) => void;
}

const ConvertToPdfNotification: React.FC<ImportNotificationProps> = ({
  notification,
  readNotification,
}): ReactElement => {
  const notificationTitle = `Attachment converted to pdf`;
  const [showDialog, setShowDialog] = useState(false);
  const handleOpenNotification = useCallback(() => setShowDialog(true), []);
  const closeDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  return (
    <Fragment>
      <NotificationItemCard
        notification={notification}
        readNotification={readNotification}
        handleOpenNotification={handleOpenNotification}
      />
      <ModalDialog
        title={notificationTitle}
        open={showDialog}
        onClose={closeDialog}
      >
        <Typography variant="body1">
          {notification.details.fileName} has been converted to pdf and added to
          attachment list for smart-tag-itr with id=
          {notification.details.recordId}
        </Typography>
      </ModalDialog>
    </Fragment>
  );
};

export default ConvertToPdfNotification;
