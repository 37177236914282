import React, { ReactElement } from 'react';
import { Card, CardContent } from '@mui/material';
import { AutocompleteInput, Button, Filter } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import { ContactCategories } from '../common/contactInput/constants';
import useSidebarFilters from '../common/hooks/useSidebarFilters';
import ContactInput from '../common/contactInput/ContactInput';
import PunchPriorityInput from '../common/PunchPriorityInput';
import PunchCategoryInput from '../common/PunchCategoryInput';
import sideFilterStyles from '../common/sideFilterStyles';
import CustomDateInput from '../common/CustomDateInput';
import DisciplineInput from '../common/DisciplineInput';
import DateRangeFilter from '../common/DateRangeFilter';
import SubsystemInput from '../common/SubsystemInput';
import ComponentInput from '../common/ComponentInput';
import LocationInput from '../common/LocationInput';
import { SideFilter } from '../common/types';
import PhaseSearchInput from '../componentTest/components/PhaseSearchInput';
import { RESOURCE_PUNCH } from '../constants';
import TextInput from '../common/TextInput';
import WithFieldCheckAccess, { Actions } from '../common/WithFieldCheckAccess';
import SideFilterBox from '../common/SideFilterBox';
import VendorInput from '../common/VendorInput/VendorInput';
import { statusChoices } from './constants';

const PunchSideFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_PUNCH, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            source="punchNo"
            name="punchNo_inc"
            label={labels['punchNo'] || 'Punch No'}
            fullWidth
            alwaysOn
          />
          <TextInput
            source="description"
            name="description_inc"
            label={labels['description'] || 'Description'}
            fullWidth
            alwaysOn
          />
          <AutocompleteInput
            source="status"
            label={labels['status'] || 'Status'}
            choices={statusChoices}
            multiple
            fullWidth
            alwaysOn
          />
          <ComponentInput label={labels['componentId']} alwaysOn multiple />
          <SubsystemInput label={labels['subsystemId']} alwaysOn multiple />
          <PhaseSearchInput
            label="Subsystem Phase"
            source="subsystem->phase"
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
          <PunchPriorityInput label={labels['priorityId']} alwaysOn multiple />
          <ContactInput
            category={ContactCategories.punchSignOff}
            source="raisedBy_inc"
            label={labels['raisedBy'] || 'Raised By'}
            canCreate={false}
            alwaysOn
          />
          <DateRangeFilter
            label={labels['raisedDate'] || 'Raised'}
            source="raisedDate"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <SideFilterBox
            sx={sideFilterStyles.sideFiltersBoxMarginBottom}
            source="boxWithFieldCheckAccess"
            alwaysOn
          >
            <WithFieldCheckAccess action={Actions.SHOW}>
              <ContactInput
                category={ContactCategories.punchSignOff}
                source="clearedBy_inc"
                label={labels['clearedBy'] || 'Cleared By'}
                canCreate={false}
                alwaysOn
              />
              <DateRangeFilter
                source="clearedDate"
                label={labels['clearedDate'] || 'Cleared'}
                dateFrom={dateFrom}
                dateTo={dateTo}
                handleDateFromChange={handleDateFromChange}
                handleDateToChange={handleDateToChange}
              />
            </WithFieldCheckAccess>
          </SideFilterBox>
          <WithFieldCheckAccess action={Actions.SHOW}>
            <ContactInput
              category={ContactCategories.punchSignOff}
              source="acceptedBy_inc"
              label={labels['acceptedBy'] || 'Accepted By'}
              canCreate={false}
              alwaysOn
            />
            <DateRangeFilter
              source="acceptedDate"
              label={labels['acceptedDate'] || 'Accepted'}
              dateFrom={dateFrom}
              dateTo={dateTo}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
            />
          </WithFieldCheckAccess>
          <ContactInput
            category={ContactCategories.punchSignOff}
            source="responsible_inc"
            label={labels['responsible'] || 'Responsible'}
            canCreate={false}
            alwaysOn
          />
          <PunchCategoryInput label={labels['punchCatId']} alwaysOn />
          <LocationInput label={labels['locationId']} alwaysOn />
          <CustomDateInput
            source="requiredDate"
            label={labels['requiredDate'] || 'Required Date'}
            alwaysOn
          />
          <CustomDateInput
            source="estimatedClearDate"
            label={labels['estimatedClearDate'] || 'Estimated Clear Date'}
            alwaysOn
          />
          <DisciplineInput
            label={labels['disciplineId'] || 'Discipline'}
            alwaysOn
          />
          <TextInput
            source="actionee"
            name="actionee_inc"
            label={labels['actionee'] || 'Actionee'}
            alwaysOn
            fullWidth
          />
          <TextInput
            source="poRmaNo"
            name="poRmaNo_inc"
            label={labels['poRmaNo'] || 'Po Rma No'}
            alwaysOn
            fullWidth
          />
          <VendorInput alwaysOn multiple />
          <TextInput
            source="workpackOverride"
            name="workpackOverride_inc"
            label={labels['workpackOverride'] || 'Workpack Override'}
            alwaysOn
            fullWidth
          />
          <TextInput
            source="correctiveAction"
            name="correctiveAction_inc"
            label={labels['correctiveAction'] || 'Corrective Action'}
            alwaysOn
            fullWidth
          />
          {labels['cfText01'] ? (
            <TextInput
              source="cfText01"
              name="cfText01_inc"
              label={labels['cfText01']}
              alwaysOn
              fullWidth
            />
          ) : null}
          {labels['cfText02'] ? (
            <TextInput
              source="cfText02"
              name="cfText02_inc"
              label={labels['cfText02']}
              alwaysOn
              fullWidth
            />
          ) : null}
          {labels['cfText03'] ? (
            <TextInput
              source="cfText03"
              name="cfText03_inc"
              label={labels['cfText03']}
              alwaysOn
              fullWidth
            />
          ) : null}
          {labels['cfText04'] ? (
            <TextInput
              source="cfText04"
              name="cfText04_inc"
              label={labels['cfText04']}
              alwaysOn
              fullWidth
            />
          ) : null}
          {labels['cfText05'] ? (
            <TextInput
              source="cfText05"
              name="cfText05_inc"
              label={labels['cfText05']}
              alwaysOn
              fullWidth
            />
          ) : null}
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button
          label="Clear filters"
          onClick={handleClearFilters}
          id="clearFilterButton"
        />
      </CardContent>
    </Card>
  );
};

export default PunchSideFilters;
